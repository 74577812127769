
.section-container{
    display: flex;
    flex-direction:column;
}

.outline-btn{
    padding: 10px 30px !important;
    border-radius: 5px ;
    @include fontStyle('small');
    color: $primaryColor;
    text-transform: uppercase;
    transition: .3s;
    background:  $primaryColor ;
    color: #fff;
    display: inline-block;
    margin-top: 24px;
}
.outline-btn:hover{
    background-color :rgb(0, 48, 119);
    transition: .3s;
    text-decoration: none;
    color: #fff
}
.about p{
    @include fontStyle(p);
}
.about p + p{
    margin-top: 15px; 

}
.darkAbout h2, .darkAbout p {
    color:$fourthColor
}
.darkAbout h2::after{
    background:$fourthColor
}
.darkAbout {
    background:$darkMode
}