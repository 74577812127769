@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
$darkMode:#4d4d4d;
$primaryColor: #4b88e4 ;
$secondaryColor: #F2F2F2;
$thirdColor: #e9e9e9 ;
$fourthColor: #D0D3D9;
$fontColor: rgb(80, 79, 79);

$fontSm: 14px;
$fontMd: 22px;
$fontLg: 28px;
$fontXl: 36px;
$fontP: 18px;

$font: 'Roboto',Arial;
$font2: 'Roboto',Arial;
$marginSubTitle: 20px;
$letterSpacing: 1px;

@mixin device($size){
    @if $size == 'sm-sm'{
        @media (max-width:260px){
            @content
        }
     };
    @if $size == 'md'{
        @media screen and (max-width: 768px){
            @content
        }
     };
     @if $size == 'lg'{
        @media (min-width: 769px){
            @content
        }
     };
     @if $size == 'lg-lg'{
        @media (max-width: 1000px){
            @content
        }
     };
     @if $size == 'xl'{
        @media (min-height:1000px) and (min-width: 1000px){
            @content
        }
     }   
   
};
@mixin fontStyle($type){
    font-weight: 300;

    @if $type == 'large'{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: $fontLg;

    };
    @if $type == 'medium'{
        font-family: 'Roboto', sans-serif;
        font-size: 1.30em;
        text-transform: uppercase

    };
    @if $type == 'small'{
        font-family: 'Roboto', sans-serif;
        font-size: $fontSm;
      
        line-height: $fontSm * 1.8;

    }
    @if $type == 'p'{
        font-family: 'Arial', sans-serif;
        font-size: $fontP;
        line-height: $fontP * 1.8;
        
    
    }
}