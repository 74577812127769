.form{
    background: #f2f2f2;
}
form  h3, .formContainer h3{
    letter-spacing: $letterSpacing;
    margin-bottom: $marginSubTitle;
    @include fontStyle(medium);
    font-weight: 500;
    
    &::after{
        content: '';
        display: block;
        height:2px;
    width: 40px;
        margin:10px 0 0 0 ;
        background: rgb(8, 8, 8);
    }
}

.networks li{
    margin: 20px 0 20px 100px;
    @include device(md){
        margin: 20px 0 20px 0px;
    }
    & svg{
        font-size: 30px;
        margin-right: 10px;
    }
    & a{
        color: $fontColor;
    }
}
.networks h3{
    margin: 0px 0 20px 100px;
    @include fontStyle(medium);;
    font-weight: 500;
    @include device(md){
        margin:0;
    }
    &::after{
        content: '';
        display: block;
        height:2px;
        width: 50px;
        margin:10px 0 0 0 ;
        background: rgb(8, 8, 8);
    }
 
}
.formFeedback{
    display:flex;
    flex-direction: column;
   
 
}
.formFeedback input{
    margin-bottom: 15px;
    outline: none;
    @include fontStyle(small);
    color: black;
    font-family: 'Roboto',Arial;
 
    
}
.formFeedback textarea{
    outline: none;
    padding: 10px;
    @include fontStyle(small);
    color: black;
    font-family: 'Roboto',Arial;
    @include device(md){
        width:100%
    }
}
.form-group {
    margin-bottom: 0;
}

.formFeedback button:hover{
   
    background-color:#032d5e;
    
}
.formFeedback label{
    font-weight: 300;
    @include fontStyle(small);
    margin-bottom: 5px;
    
}
.formFeedback input, .formFeedback textarea, .formFeedback button{
    border-radius: 4px;
    border: rgb(106, 103, 103) solid 1px;
    
}
.formFeedback button{
    @include fontStyle(small);
    height:35px;
    width:100%;
    cursor: pointer;
    outline: none;
    transition: all ease-in-out .3s;
    display: inline-block;
    margin-top: 10px;
    background-color:$primaryColor;
    color: white;
    border: none
}

.spinner{
    color:$primaryColor!important;
    margin: 15px
}
.darkForm{
   background: $darkMode;
}
.darkForm h3,.darkForm label,.darkForm .spinner, .darkForm h3::after,.darkForm .github{
    color:$fourthColor!important
}
.darkForm h3::after{
    background: $fourthColor
}
.darkForm .formFeedback input, .darkForm .formFeedback textarea{
   background: #f9f9f9
}
.darkForm .formFeedback input, .darkForm .formFeedback textarea, .darkForm button{
    color: $darkMode
}
.darkForm button{
    color: $thirdColor
}
.darkForm .contacts a{
    color: $fourthColor !important
}
