header {
  padding-top: 60px;
  background: #f2f2f2;
  @include device(md){
      padding-top: 30px
  }
  h2 {
    @include fontStyle(large);
    color: $fontColor;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 20px;
    @include device(md){
        margin-top: 30px
    }
    span {
      animation: textHeader 4s infinite;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      position: relative;
      animation-timing-function: steps(25, end);
      height: 30px;
      &::after {
        content: "|";
        position: absolute;
        right: 0;
        animation: caret infinite;
        animation-duration: 1s;
        animation-timing-function: steps(1, end);
      }
    }
  }
}

@keyframes caret {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes textHeader {
  0%,
  100% {
    width: 0;
  }
  20%,
  80% {
    width: 130px;
  }
}

.listNetworks {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

nav ul h1 a {
  @include fontStyle(large);
  color: $primaryColor;
  text-decoration: none !important;
  font-size: 30px;
}
nav ul h1 {
  margin: 5px 0;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
}

.hamburger {
  cursor: pointer;
  display: none;
  height: 25px;
  width: 25px;
  margin-right: 15px;
  color: $primaryColor;
  @include device(md) {
    display: block !important;
  }
}

nav {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  width: 100%;
  z-index: 99;
  top: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav svg {
  display: flex;
  justify-content: flex-end;
}

nav li {
  @include device(lg) {
    margin: auto 0;
  }
}
.container-img-header{
   display: grid;
   min-height: 400px;
   @include device(md) {
     min-height: auto;
     img{
      height: 300px;
     }
   }
}
.container-img-header img{
    opacity: 0;
    visibility: hidden;
    transition: .3s;
    grid-area: 1/1;
    &.active{
        opacity: 1;
        visibility: visible;
        transition: .3s;
    }
}
.list {
    >div{
        display:flex;
        align-items: center;
    }
  @include device(md) {
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    position: fixed;
    left: -100%;
    height: 100vh;
    width: 100%;
    z-index: 99;
    transition:  .3s;
    > div {
      background-color: $primaryColor;
      top: 0;
      position: absolute;
      left: -100%;
      height: 100vh;
      width: 60%;
      padding: 40px 0 0 20px;
      z-index: 100;
      transition: .3s;
      flex-direction: column;
      align-items: start;
    }
    li + li {
      margin-left: 0px;
      margin-top: 20px;
    }
    li a {
      color: #fff;
    }
    &.active,
    &.active > div {
      left: 0;
    }
    &.active > div {
      transition: .3s;
    }
    &:active {
      transition: none !important;
    }
  }
}

.darkNav .scroll-nav .active,
.darkNav .scroll-nav .list {
  background: $darkMode;
}
 .dark-header  {
  background: $darkMode;
  h2, p{
      color: #fff
    }
  }
  .darkNav a,
  .darkNav .hamburger {
  color: $fourthColor;
}
.scroll-nav .list {
  @include device(lg) {
    background: none !important;
  }
}

nav ul {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  transition: 0.4s;
  height: 100%;
  align-items: center;
}

nav ul li + li {
  margin-left: 20px;
}

nav li a {
  font-size: 18px;
  font-family: $font;
  transition: 0.2s;
  color: $primaryColor;
  font-weight: 500;
  &:hover {
    color: $fontColor;
  }
}
nav .contact-img {
  color: $primaryColor;
  height: 30px;
  width: 30px;
  margin: 10px;
}
nav ul .listNetworks li {
  padding: 5px;
}
