.btnContainer{
    width:70px;
    height:35px;
    border-radius: 20px;
    cursor:pointer;
    border:solid 2px $fourthColor;
    position: fixed;
    bottom: 10px ;
    right: 20px;
    z-index:999;
}

[theme='dark'].btnContainer{
    background:$secondaryColor;
}
.btnContent{
    position:relative;
    width:100%;
    border-radius: 20px;
    height:100%;
    display:flex;
    justify-content: space-around;
    align-items: center;
    background: $primaryColor 
}
.ball{
    position: absolute;
    transition: 200ms ease;
    left:0;
    top:0;
    height:100%;
    width:50%;
    border-radius: 50%;
    background:$secondaryColor;
}
[theme='dark'] .ball{
    left:50%;
}
.img-sun{
  color:#fafd0f;
  height:25px;
  width: 25px;
}
.img-moon{
  color:$secondaryColor;
  height:25px;
  width: 25px;
}