
*{
    box-sizing: border-box;
    margin:0;
    list-style:none;
    text-decoration: none;
    font-family: Arial;
    transition:  background 300ms ease-in;

}

html{
    scroll-behavior: smooth;
}
body, #root {
    height:100%;
    font-family: "Roboto";
}
img{
    display: block;
    max-width: 100%
}
button{
    cursor: pointer;
   outline-color: $primaryColor;
}

@media (min-width: 769px){
    .list{
        display: flex;
        transition: none !important;
    }
}

