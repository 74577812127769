main {
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include device(lg-lg) {
    align-items: flex-start;
  }
}

main section h2 {
  letter-spacing: $letterSpacing;
  text-align: center;
  margin-bottom: 50px;
  @include fontStyle(medium);
  font-weight: 500;
  @include device(md) {
    margin-bottom: 20px;
  }
}
section.projects h2::after {
  content: "";
  display: block;
  height: 2px;
  width: 70px;
  margin: 10px auto 0 auto;
  background: rgb(8, 8, 8);
}

.projects {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 60px 0;
  @include device(lg-lg) {
    text-align: center;
  }
}

.projects .card-button a {
  border-radius: 4px;
  transition: linear all 100ms;
  @include fontStyle(small);
  text-decoration: none;
  display: block;
  max-width: 90px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.projects .card-button a svg {
  margin-right: 5px;
  font-size: 16px;
  display: block;
}
.projects .cardInfo + a::after {
  content: ">>";
  display: inline-block;
  position: relative;
  font-weight: 500;
  margin-left: 2px;
  width: auto;
}
.projects a {
  text-decoration: none;
}
.projects .card-content:hover {
  transform: scale(1.03);
  transition: 0.1s transform linear;
}

.projects .card-content .card-button a {
  color: $primaryColor !important;
}
.projects .card-content .card-button a:first-child {
  color: $darkMode !important;
}
.projects .card-content .card-button a:hover {
  color: rgb(255, 59, 59) !important;
}
.card-content {
  position: relative;
  text-decoration: none !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #fff;

  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardHeader {
  position: relative;
  font-weight: 700;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.cardHeader,
.card-button,
.cardInfo {
  padding: 10px;
}

.cardHeader h3 {
  @include fontStyle(medium);
  color: $fontColor;
}
.card-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardHeader h5 {
  @include fontStyle(small);
  font-weight: 500;
  color: $fontColor;
  text-transform: uppercase;
}
.full-stack h5::before {
  background-color: $primaryColor;
}
.cardHeader h5::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: $primaryColor;
}

.cardInfo {
  text-align: start;
  padding: 20px 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.cardInfo h4 {
  @include fontStyle(small);
  text-transform: uppercase;
  font-family: "Roboto";
  color: rgb(107, 107, 107);
}
.cardInfo p {
  @include fontStyle(small);
  margin-top: 30px;
  font-family: "Roboto";
  color: rgb(107, 107, 107);
}

.darkMain {
  background: $darkMode;
  border: none;
}
.darkMain .card-content {
  box-shadow: none;
  background: #f9f9f9
}
.darkMain h2 {
  color: $fourthColor;
}
.darkMain section h2::after {
  background: $fourthColor;
}
